<template lang="pug">
basicPages(:title="$t('cookies.title')")
  p {{ $t('cookies.box1.text1') }}
  p {{ $t('cookies.box1.text2') }}
  p {{ $t('cookies.box1.text3') }}
  p {{ $t('cookies.box1.text4') }}
  h2 {{ $t('cookies.header1') }}
  p(v-html="$t('cookies.box2.text1')")
  p(v-html="$t('cookies.box2.li1')")
  p(v-html="$t('cookies.box2.li2')")
  p(v-html="$t('cookies.box2.li3')")
  p(v-html="$t('cookies.box2.li4')")
  p(v-html="$t('cookies.box2.li5')")
  p(v-html="$t('cookies.box2.li6')")
  p(v-html="$t('cookies.box2.li7')")
  p(v-html="$t('cookies.box2.li8')")
  p(v-html="$t('cookies.box2.li9')")
  h2(v-html="$t('cookies.header2')")
  p(v-html="$t('cookies.box3.text1')")
  h2(v-html="$t('cookies.header3')")
  p(v-html="$t('cookies.box4.text1')")
  h2(v-html="$t('cookies.header4')")
  p(v-html="$t('cookies.box5.text1')")
  p(v-html="$t('cookies.box5.text2')")
  p(v-html="$t('cookies.box5.text3')")
  p(v-html="$t('cookies.box5.text4')")
  p(v-html="$t('cookies.box5.text5')")
  p(v-html="$t('cookies.box5.text6')")
  p(v-html="$t('cookies.box5.text7')")
  p(v-html="$t('cookies.box5.text8')")
  p(v-html="$t('cookies.box5.text9')")

  .overflow-x-auto.px-1.mt-10
    table.table-auto.w-full
      thead.bg-gray-100
        tr
          th.p-3 Nombre
          th.p-3 Origen
          th.p-3 Finalidad
          th.p-3 Tipo
          th.p-3 Duración
      tbody
        tr
          td.border.p-3 _ga
          td.border.p-3 {{ $t('appDomain') }}
          td.border.p-3 Analytic
          td.border.p-3 Tercero
          td.border.p-3 2 años
        tr
          td.border.p-3 _gat
          td.border.p-3 {{ $t('appDomain') }}
          td.border.p-3 Analytic
          td.border.p-3 Tercero
          td.border.p-3 10 min.
        tr
          td.border.p-3 NID
          td.border.p-3 google.com
          td.border.p-3 Analytic
          td.border.p-3 Tercero
          td.border.p-3 4 meses
        tr
          td.border.p-3 SNID
          td.border.p-3 google.es
          td.border.p-3 Analytic
          td.border.p-3 Tercero
          td.border.p-3 6 meses
</template>

<script>
import basicPages from "@/components/structure/views/basicPages";
export default {
  name: "Cookies",
  components: {
    basicPages,
  },
};
</script>

<style lang="scss">
</style>
